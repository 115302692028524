import React, { useState, useRef, useEffect } from 'react';
import Map, { Source, Layer, Popup } from 'react-map-gl';
import { clusterLayer, clusterCountLayer, unclusteredPointLayer } from './Layer';
import './map.css';
import { villageMapData } from '../../actions/mapActions';
import { toast } from 'react-toastify';
import Modal from '../../Utilities/Modal';

function MapComponent() {
  const [geojsonData, setGeojsonData] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null); // State to track popup info
  const [clusterVillageList, setClusterVillageList] = useState([]); // State to store villages in cluster
  const [showClusterPopup, setShowClusterPopup] = useState(false); // State to show/hide cluster popup
  const [searchQuery, setSearchQuery] = useState(''); // State for search input
  const mapref = useRef(null);

  useEffect(() => {
    // Fetch the GeoJSON data from the API
    const fetchData = async () => {
      try {
        const response = await villageMapData();
        setGeojsonData(response.data);
      } catch (error) {
        console.error('Error fetching GeoJSON data:', error);
      }
    };

    fetchData();
  }, []);

  const handleClickOnMap = async (e) => {
    const map = mapref.current.getMap();
    const features = map.queryRenderedFeatures(e.point, {
      layers: ['clusters', 'unclustered-point'], // The cluster and unclustered point layers
    });

    if (features.length) {
      const feature = features[0];
      console.log('feature of click is ', feature);
      if (feature.layer.id === 'clusters') {
        const clusterId = feature.properties.cluster_id;
        const source = map.getSource('earthquakes');
        console.log('source is ', source);
        // Get the villages in the cluster
        source.getClusterLeaves(clusterId, 100, 0, (err, leaves) => {
          console.log('cluster data inside source ', clusterId, leaves);
          if (err) {
            console.error('Error getting cluster leaves:', err);
            return;
          }

          // Store the list of villages in the cluster
          const villageList = leaves.map((leaf) => ({
            name: leaf.properties.name,
            count: leaf.properties.count,
          }));
          setClusterVillageList(villageList);
          setShowClusterPopup(true);
        });
      } else if (feature.layer.id === 'unclustered-point') {
        const coordinates = feature.geometry.coordinates.slice();
        const { count, Installed_count, name } = feature.properties;

        // Set popup info for the individual village
        setPopupInfo({
          lngLat: coordinates,
          count,
          Installed_count,
          village: name,
        });
      }
    } else {
      setPopupInfo(null);
      setShowClusterPopup(false);
    }
  };

  // Function to handle the village search and move the map
  const handleSearch = () => {
    if (!searchQuery || !geojsonData) return;

    // Find the feature with the matching village name
    const feature = geojsonData.features.find((f) => f.properties.name.toLowerCase() === searchQuery.toLowerCase());

    if (feature) {
      const [longitude, latitude] = feature.geometry.coordinates;

      // Move the map to the village's coordinates
      const map = mapref.current.getMap();
      map.flyTo({
        center: [longitude, latitude],
        zoom: 12,
        essential: true,
      });

      // Set popup info to show village details
      setPopupInfo({
        lngLat: [longitude, latitude],
        count: feature.properties.count,
        Installed_count: feature.properties.Installed_count,
        village: feature.properties.name,
      });
    } else {
      toast.error('कृपया सही गांव का नाम दर्ज करें');
    }
  };

  return (
    <div>
      {/* Search Bar */}
      <div className="search-bar">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search for a village..."
        />
        <button onClick={handleSearch}>Search</button>
      </div>

      <Map
        ref={mapref}
        initialViewState={{
          longitude: 78.9629,
          latitude: 22.5937,
          zoom: 8,
          maxZoom: 16,
          pitch: 0,
          bearing: 0,
        }}
        reuseMaps={true}
        style={{ width: '100vw', height: '100vh' }}
        mapStyle="mapbox://styles/mapbox/dark-v9"
        mapboxAccessToken="pk.eyJ1Ijoic2luZ2h2aXNoYW4wMDI5IiwiYSI6ImNsdTVwNDdtajE0OGgya254bW1qcGxuc2oifQ.O5Mw_RmNEyFN6N4eZucRWw"
        onClick={handleClickOnMap}
      >
        <Source
          id="earthquakes"
          type="geojson"
          data={geojsonData}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={50}
          clusterProperties={{
            sum_count: ['+', ['get', 'count']],
            installed_count: ['+', ['coalesce', ['get', 'Installed_count'], 0]],
          }}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
        </Source>

        {popupInfo && (
          <Popup
            longitude={popupInfo.lngLat[0]}
            latitude={popupInfo.lngLat[1]}
            anchor="top"
            closeOnClick={true}
            onClose={() => setPopupInfo(null)}
            className="popup"
          >
            <div className="popup-content">
              <p>
                <strong>गांव:</strong>
                <a
                  target="_blank"
                  href={`/villages/filterVillages?name=${popupInfo.village}`}
                  style={{ marginLeft: '8px', textDecoration: 'none' }}
                >
                  {popupInfo.village}
                </a>
              </p>

              <p>
                <strong>Count:</strong> {popupInfo.count}
              </p>
              <p>
                <strong>Installed Count:</strong> {popupInfo.Installed_count || 0}
              </p>
            </div>
          </Popup>
        )}

        {showClusterPopup && (
          <Modal toggle={() => setShowClusterPopup(false)}>
            <div className="cluster-popup">
              <h4>Villages in this cluster ({clusterVillageList.length}):</h4> {/* Total count */}
              <button onClick={() => setShowClusterPopup(false)}>Close</button>
              <ul>
                {clusterVillageList.map((village, index) => (
                  <li key={index}>
                    <a
                      target="_blank"
                      href={`/villages/filterVillages?name=${village.name}`}
                      style={{ marginLeft: '8px', textDecoration: 'none' }}
                    >
                      {village.name}
                    </a>{' '}
                    ({village.count}/{village.installed_count || 0})
                  </li>
                ))}
              </ul>
            </div>
          </Modal>
        )}
      </Map>
    </div>
  );
}

export default MapComponent;

import { getApiWrapper } from './token-wrapper-function';

export const villageData = async (startDate, endDate) => {
  const result = await getApiWrapper(`/api/map/report/usercount?reg=true&start=${startDate}&end=${endDate}`);
  return result;
};

export const villageMapData = async () => {
  const result = await getApiWrapper(`/api/report/village_map_data`);
  return result;
};
